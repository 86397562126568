<template>
  <b-container class="d-flex align-items-center justify-content-center" style="background: #212529" fluid id="auth">
    <b-row>
      <div style="width: 300px">
        <h5 class="mb-4" style="color: white">PRC Login</h5>
        <b-alert class="mb-4" variant="warning" :show="auth_error">
          Username or password is incorrect
          <!-- <span v-for="(item, i) in auth_error" :key="i">{{ item }}</span> -->
        </b-alert>
        <b-form>
          <b-form-group id="input-group-1" label="" label-for="input-1">
            <b-form-input id="input-1" v-model="form.name" type="text" required placeholder="Email"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="" label-for="input-2">
            <b-form-input id="input-2" v-model="form.password" type="password" required placeholder="Password"></b-form-input>
          </b-form-group>
          <b-button class="mt-4 mb-5" :disabled="submit" block type="submit" variant="outline-light" @click="onSubmit()">Login</b-button>
        </b-form>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios'

@Component
export default class Auth extends Vue  {
  form = {
    name: '',
    password: ''
  }
  submit = false
  auth_error = false

  onSubmit() {
    this.submit = true
    this.auth_error = false
    axios.post(`${process.env.VUE_APP_API_ENDPOINT}/auth/login`, this.form)
    .then(res => {
      axios.defaults.headers.common.authorization = `Bearer ${res.data.token}`
      res.data.email = this.form.name
      this.$store.commit('saveUser', res.data)
      this.$router.push({
        name: 'Transaction'
      })
      this.submit = false
    })
    .catch(err => {
      console.log(err.response);
      this.submit = false
      this.auth_error = true
    })
  }
}
</script>
