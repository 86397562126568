
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment'
import axios from 'axios'

interface user {
  id?: number
  name: string
  password?: string
  email: string
  role_id: number
  brand_ids: number[]
}

@Component
export default class User extends Vue {
  is_busy:boolean = true
  init:boolean = false
  users: any[] = []
  brands: any[] = []
  form_mode: string = 'Create'
  fields = ['name','email',{ key: 'brand_ids', label: 'Brands' },{ key: 'role_id', label: 'Role' },'created_at',{ key: 'id', label: 'Edit' }]
  syncing: boolean = false

  user: user = {
    name: "",
    password: "",
    email: "",
    role_id: 3,
    brand_ids: [],
  }

  role_options = [
    { value: 1, text: 'Admin' },
    { value: 2, text: 'Brand Admin' },
    { value: 3, text: 'User' }
  ]

  breadcrumbs = [{
    text: 'PRC V.2',
    to: '/transactions'
  },{
    text: 'Users',
    active: true
  }]

  parseDate(dt:string) {
    return moment(dt).format('DD/MM/YYYY HH:mm:ss')
  }

  mounted() {
    this.getUser()
  }

  getBrand(brand_id: number) {
    const brand = this.brands.find(b => b.id === brand_id);
    if(brand) {
      return brand.name
    } else {
      return 'Unknown Brand'
    }
  }

  addUser() {
    this.form_mode = 'Create'
    this.user = { name: "", password: "", email: "", role_id: 3, brand_ids: [] }
    this.$bvModal.show('user-modal')
  }

  editUser(id:number) {
    this.form_mode = 'Edit'
    this.user = this.users.find(u => u.id == id);
    this.$bvModal.show('user-modal')
  }

  getUser() {
    Promise.all([
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/user`),
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/brand`)
    ]).then(res => {
      this.users = res[0].data
      this.brands = res[1].data
      this.init = true
      this.is_busy = false
    }).catch(err => {
      console.log(err);
    })
  }

  saveUser(event:any) {
    event.preventDefault()
    console.log(event);
    
    this.is_busy = true
    if (this.form_mode == 'Create') {
      axios.post(`${process.env.VUE_APP_API_ENDPOINT}/user`, this.user)
      .then(res => {
        this.getUser();
        this.$bvModal.hide('user-modal')
        this.is_busy = false
      })
    } else {
      if (!this.user.password) {
        delete this.user.password; 
      }
      axios.put(`${process.env.VUE_APP_API_ENDPOINT}/user/${this.user.id}`, this.user)
      .then(res => {
        this.$bvModal.hide('user-modal')
        this.getUser();
        this.is_busy = false
      })
    }
  }
}
