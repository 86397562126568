import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '@/assets/stylesheet.scss'
import axios from 'axios'

let init = false
Vue.config.productionTip = false
Vue.prototype.$
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


if (!init && store.state.user && store.state.user.token) {
  axios.defaults.headers.common.authorization = `Bearer ${store.state.user.token}` 
  init = true
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status == 401) {
    store.commit('saveUser', null)
    if (router.currentRoute.name !== 'Auth') {
      router.push({ name: 'Auth' }) 
    }
    return Promise.reject(error);
  }
  return error;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
