
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import lodash from 'lodash';

interface T2POrderFilteringI {
  
    channel_name: string;
    use_filter: boolean;
    order_method ?: string;
    platform ?: string;
    item_id_txt ?: string;
    item_ids ?: number[];
    filter_type ?: string;
    is_use_order_method ?: boolean;
    is_use_platform ?: boolean;
    is_use_item_id ?: boolean;

}

interface BrandSettings {
  earn_method: number,
  application_id?: string,
  application_secret?: string, 
  endpoint: string | null,
  endpoint_token: string | null,
  sync_time: number,
  is_reset?: false,
  sync_method: number,
  ftp_username?: string,
  ftp_password?: string,
  server_ip?: string,
  batch_sync_time?: boolean,
  send_email?: boolean,
  email_list?: string,
  force_start_of_day?: boolean,
  allow_order_method?: any | null,
  t2p_order_filtering : T2POrderFilteringI[],
  brand_partner_config : BrandPartnerConfig[]
}

interface Brands {
  id: number,
  shortname: string,
  name : string
}

interface BrandPartnerConfig {
  brand_uid : number,
  is_config : boolean,
  store_bu_code : string,
  action_code : string,
  partner_items : PartnerItem[],
  partner_items_text ?: string
}

interface PartnerItem {
  source_item_id : string
}
@Component
export default class BrandSetting extends Vue {

  settingTemplate = {
    earn_method: 1,
    endpoint: 'Your API Endpoint',
    endpoint_token: 'Your AUTH Token',
    sync_time: 1,
    sync_method: 1,
    t2p_order_filtering:[],
    brand_partner_config:[]
  }
  brand_partner_is_config = {};
  t2p_order_filtering_fields = [
          'index',
          {
            key: 'filter_type',
            label: 'Type',
          },
          {
            key: 'channel_name',
            label: 'Output Channel (Required)',
          },
          {
            key: 'use_filter',
            label: 'Use Filter',
          },
          {
            key: 'order_method',
            label: 'Order Method',
          },
          {
            key: 'platform',
            label: 'Platform',
          },
          {
            key: 'item_id_txt',
            label: 'Item ID (, seperated)',
          },
          'operation'
  ]

  t2p_filter_type_options = [
          { value: "in", text: 'Include' },
          { value: 'ex', text: 'Exclude' }
        ]
  brand_partner_fields = [
          {
            key: 'index',
            label: 'Is config?',
          },
          {
            key: 'shortname',
            label: 'Brand',
          },
          {
            key: 'settings',
            label: 'Settings',
          },
  ];
  new_source_item_id : string[] = [];

  brand_partner_config : BrandPartnerConfig[] = [];
  all_brands : Brands[] = [];
  brand_settings: BrandSettings = this.settingTemplate;
  brand_id:string = "";
  show_allow_order_method: boolean = false
  show_t2p_order_filtering: boolean = false

  @Prop(String) brand_name: string | undefined = "";

  @Watch('$route', { immediate: true })
  async changeBrand(brand:any) {
    await this.getSettings(brand.params.id)
  }

  async created() {
    let params_id = this.$route.params.id;


    if (this.$route.params.id) {
      await this.getSettings(params_id)
      console.log("this.brand_partner_config", this.brand_partner_config);
    }



  }

  async getAllBrands() : Promise<Brands[]> {
    let { data  } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/brand`);
    return (data);
  }
  reloadT2POrderFiltering() {
    this.brand_settings.t2p_order_filtering = lodash.cloneDeep(this.brand_settings.t2p_order_filtering);
  }

  async getSettings(brand_id:string) {
    this.brand_id = brand_id
    this.show_allow_order_method = false

    let params_id = this.$route.params.id;
    let res = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/brand/${brand_id}`);
  
    if (res.data.brand_settings)
    {
      this.brand_settings = res.data.brand_settings
      if (!this.brand_settings.t2p_order_filtering)
      {
        this.brand_settings.t2p_order_filtering = [];
      }
      
      this.brand_settings.t2p_order_filtering = lodash.map(this.brand_settings.t2p_order_filtering, function(item){

        item.filter_type = item.channel_name == "null" ? "ex" : "in";
        item.is_use_order_method = item.order_method && item.order_method != "null" ? true : false;
        item.is_use_platform = item.platform && item.platform != "null" ? true : false;
        item.is_use_item_id = item.item_ids && item.item_ids.length > 0 ? true : false;
        item.item_id_txt = item.item_ids && item.item_ids.length > 0 ? item.item_ids.join(",") : "";
        return item
      });

      if(this.brand_settings.allow_order_method) {
        this.show_allow_order_method = true

        Object.entries(this.brand_settings.allow_order_method).forEach(([key, value]) => {
          if(!value) {
            this.brand_settings.allow_order_method[key] = 'null'
          }
        })
        this.brand_settings.allow_order_method = this.brand_settings.allow_order_method.toString()
      }
    }
    else
    {
        this.brand_settings = this.settingTemplate
    }

    let global_brand_partner_config = this.brand_settings.brand_partner_config;
    
    this.all_brands = lodash.filter(await this.getAllBrands(), function(item){
      return item.id != parseInt(params_id);
    });
      
    let cur_brand = lodash.filter(await this.getAllBrands(), { id : parseInt(params_id) } );
    this.brand_name = cur_brand[0].name;

              

    this.brand_partner_config = lodash.map(this.all_brands, function(item){
      let setting = lodash.filter(global_brand_partner_config, { brand_uid : item.id } );
      console.log("setting", setting);
      if (setting.length == 0)
      {
        return {
          brand_uid : item.id,
          is_config : false,
          store_bu_code : "",
          action_code : "",
          partner_items : [],
          partner_items_text : ""
        };
      }
      else
      {
        setting[0].partner_items_text = lodash.map(setting[0].partner_items, "source_item_id").join(", ");
        return setting[0];
      }
    });
  }
  addItem(brand_idx: number)
  {
    if ( this.new_source_item_id[brand_idx].toString().trim() == "" )
    {
      alert("Please Insert ItemID");
      return;
    }

    let chk = lodash.filter(this.brand_partner_config[brand_idx].partner_items, { source_item_id : this.new_source_item_id[brand_idx].toString().trim() });
    if (chk.length > 0)
    {
      alert("Duplcate ItemID");
      return;
    }

    this.brand_partner_config[brand_idx].partner_items.push({ source_item_id : this.new_source_item_id[brand_idx].toString().trim() });

  }

  removeItem(brand_idx : number, item_idx : number)
  {
    if (this.brand_partner_config[brand_idx] && this.brand_partner_config[brand_idx].partner_items[item_idx])
    {
      let delete_item = this.brand_partner_config[brand_idx].partner_items[item_idx];
       this.brand_partner_config[brand_idx].partner_items = lodash.filter(lodash.cloneDeep( this.brand_partner_config[brand_idx].partner_items), function(item){
         return lodash.isEqual(delete_item, item) == false;
       });
    }
  }

  addOrderFiltering()
  {
    this.brand_settings.t2p_order_filtering.push({
      channel_name : "",
      use_filter : true
    });
    
  }

  moveUpOrderFiltering(idx:number) {
    let buff = lodash.cloneDeep(this.brand_settings.t2p_order_filtering[idx])
    this.brand_settings.t2p_order_filtering[idx] = this.brand_settings.t2p_order_filtering[idx-1];
    this.brand_settings.t2p_order_filtering[idx - 1] = buff;
    this.brand_settings.t2p_order_filtering = lodash.cloneDeep(this.brand_settings.t2p_order_filtering);
  }

  moveDownOrderFiltering(idx:number) {
    let buff = lodash.cloneDeep(this.brand_settings.t2p_order_filtering[idx])
    this.brand_settings.t2p_order_filtering[idx] = this.brand_settings.t2p_order_filtering[idx+1];
    this.brand_settings.t2p_order_filtering[idx + 1] = buff;
    this.brand_settings.t2p_order_filtering = lodash.cloneDeep(this.brand_settings.t2p_order_filtering);
  }

  deleteOrderFiltering(idx:number) {
    lodash.pullAt(this.brand_settings.t2p_order_filtering, [idx]);
    this.brand_settings.t2p_order_filtering = lodash.cloneDeep(this.brand_settings.t2p_order_filtering);
  }

  onSubmit() {
    let params = this.brand_settings
    if(this.brand_settings.allow_order_method == '' || this.brand_settings.earn_method == 2 || this.show_allow_order_method == false) {
      this.brand_settings.allow_order_method = null
    } else {
      let str = this.brand_settings.allow_order_method.replace(/null/g, '')
      params = {...this.brand_settings, "allow_order_method" : str.split(',')}
    }

    if (this.brand_settings.earn_method == 1)
    {
      this.brand_settings.t2p_order_filtering = [];
    }
    else
    {
      //valid t2p_order_filtering
      let is_valid = lodash.reduce(this.brand_settings.t2p_order_filtering, function( result: any, item: T2POrderFilteringI ) {
        if (item.channel_name.trim() == "" && item.filter_type == "in")
        {
          alert("A channel name at order filtering is empty.");
          return false;
        }

        if (item.is_use_item_id)
        {
          if (!item.item_id_txt || item.item_id_txt == "" )
          {
            alert("A item id at order filtering is empty.");
            return false;
          }
          else
          {
            let item_ids = item.item_id_txt.split(',');
            let chk_item = true;
            for (let i = 0; i < item_ids.length ; i++)
            {
              if (Number.isInteger(parseInt(item_ids[i].trim())) == false)
              {
                chk_item = false;
              }
            }

            if (chk_item == false)
            {
              alert("A item id must be an integer.");
              return false;
            }
          }
        }
        return result;
      }, true);

      if (!is_valid)
        return;

      if (this.brand_settings.t2p_order_filtering.length == 0)
      {
        alert("At least one order filtering must be configured.");
        return;
      }

      //parse itemm
      this.brand_settings.t2p_order_filtering = lodash.map(lodash.cloneDeep(this.brand_settings.t2p_order_filtering), function(item) {
        if (item.filter_type == "ex")
          item.channel_name = "null";
        if (item.is_use_order_method == false)
          item.order_method = "null";
        if (item.is_use_platform == false)
          item.platform = "null";
        if (item.is_use_item_id == false || !item.item_id_txt)
          item.item_ids = []
        else
        {
          item.item_ids = lodash.map(item.item_id_txt.split(","), function(item) { return parseInt(item.trim())});
        }
        return item;
      });
    }

    //valid brand partner item
    let local_all_brands = this.all_brands;
    let is_brand_partner_valid = lodash.reduce(this.brand_partner_config, function(result, item) {
      if (item.is_config)
      {
        let brand = lodash.filter(local_all_brands, { id : item.brand_uid});
        if (item.store_bu_code.toString().trim() == "")
        {
          alert("Please insert brand store BU code for " + brand[0].shortname)
          return false;
        }

        //convert partner_items_text to partner_items
        let partner_items_text = item?.partner_items_text;
        if (!partner_items_text)
        {
          alert("Please insert brand partner item for " + brand[0].shortname)
          return false;
        }
        item.partner_items = lodash.map(partner_items_text.replace(/\n/g, ",").split(","), function(item){
          return { source_item_id : item.trim() }
        });

        if (item.partner_items.length == 0)
        {
          alert("Please insert brand partner item for " + brand[0].shortname)
          return false;
        }
      }

      return result;
    }, true );

    if (is_brand_partner_valid == false)
      return;
    params.brand_partner_config = this.brand_partner_config;
    axios.put(`${process.env.VUE_APP_API_ENDPOINT}/brand/setting/${this.brand_id}`, params)
    .then(res => {
      this.$bvModal.show('successSettingModal')
    })
    .catch(err => {
      this.$bvModal.show('errorSettingModal')
    })
  }

  
}
