
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment'
// @ts-ignore
import vSelect from 'vue-select'
import axios from 'axios'

interface getTransactionParams {
  page: string
  per_page: string
  order_id?: (string | null)
  minor_id?: (string | null)
  brand_id?: string
  business_id?: string
  purchase_date_from?: string
  purchase_date_to?: string
}

@Component({
  components: {
    vSelect
  }
})
export default class Transaction extends Vue {
  is_busy:boolean = true
  init:boolean = false
  transaction = null
  master_receipt = null
  show_filter:boolean = false

  breadcrumbs = [{
    text: 'PRC V.2',
    to: '/transactions'
  },{
    text: 'Transactions',
    active: true
  }]

  selected_brand = this.$route.query.brand_id || null
  selected_store = this.$route.query.business_id || {
    id: -2,
    name: 'All Stores',
    brand_alias: null,
    business_id: null
  }
  order_id = this.$route.query.order_id || null
  minor_id = this.$route.query.minor_id || null
  purchase_date_from = this.$route.query.purchase_date_from || null
  purchase_date_to = this.$route.query.purchase_date_to || null
  brands: any[] = []
  stores: any[] = []
  transactions: any[] = []
  current_page: any = this.$route.query.page || "1"
  per_page: string = "20"
  total_rows: number | null = null
  fields = ['order_id','minor_id',{ key: 'store', label: 'Branch' },'total_amount',{ key: 'created_at', label: 'Received Time' },'sync_date','status','platform']

  params:any = { 
    page: this.current_page || 1,
    per_page: this.per_page || 20,
    order_id: this.order_id || null,
    brand_id: this.selected_brand || null
  }

  mounted() {
    let query = {}
    if (this.$route.query == {}) {
      query = this.$route.query
    } else {

      if (this.$route.query) {
        this.params = Object.assign({}, this.$route.query);
      }

      if (!this.$route.query.page || !this.$route.query.per_page) {
        this.params.page = 1
        this.params.per_page = 20
      }
      query = this.params
    }

    this.getTransaction(query)
    this.getStores()
    this.getBrand()
    this.filterStore()
  }

  search() {
    if (this.order_id) {
      this.params.order_id = this.order_id  
    } else {
      delete this.params.order_id
    }

    if (this.minor_id) {
      this.params.minor_id = this.minor_id  
    } else {
      delete this.params.minor_id
    }

    if (this.purchase_date_from) {
      // @ts-ignore
      this.params.purchase_date_from = this.purchase_date_from
      // this.params.purchase_date_from = moment(this.purchase_date_from).utc().format()
    } else {
      delete this.params.purchase_date_from
    }

    if (this.purchase_date_to) {
      // @ts-ignore
      this.params.purchase_date_to = this.purchase_date_to
      // this.params.purchase_date_to = moment(this.purchase_date_to).utc().format()
    } else {
      delete this.params.purchase_date_to
    }

    if (this.selected_brand) {
      this.params.brand_id = this.selected_brand  
    } else {
      delete this.params.brand_id
    }

    // @ts-ignore
    if (this.selected_store.business_id) {
      // @ts-ignore
      this.params.business_id = this.selected_store.business_id  
    } else {
      delete this.params.business_id
    }
    
    if (JSON.stringify(this.$route.query) !== JSON.stringify(this.params)) {
      this.is_busy = true
      this.$router.push({
        name: 'Transaction',
        query: { ...this.params }
      })
    }
  }

  showThansaction(transaction_id: string) {
    this.$bvModal.show('transactionModal');
    axios.get(`${process.env.VUE_APP_API_ENDPOINT}/receipts/${transaction_id}`)
    .then(res => {
      this.master_receipt = null;
      this.transaction = res.data
      console.log(res.data);
    })
  }

  parseDateFromNow(dt:string) {
    return moment(dt).fromNow()
  }

  parseDateFormat(dt:string) {
    return moment(dt).format('DD/MM/YYYY HH:mm')
  }

  onChangePage(page:string) {
    this.params.page = page
    this.$router.push({
      name: 'Transaction',
      query: { ...this.params }
    })
  }

  getTransaction(query?:any) {
    console.log(query);
    
    axios.get(`${process.env.VUE_APP_API_ENDPOINT}/receipts`,{
      params: query
    }).then(res => {
      this.transactions = res.data.data
      this.current_page = res.data.page_idx
      this.per_page = res.data.per_page
      this.total_rows = res.data.total
      this.is_busy = false
      this.init = true

      if (this.show_filter) {
        this.show_filter = false
      }
    })
  }

  getMasterReceipt(receipt_id : string) {
      
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/receipts/master/` + receipt_id ).then(res => {
        this.master_receipt = res.data
      })
  }

  getBrand() {
    axios.get(`${process.env.VUE_APP_API_ENDPOINT}/brand`).then(res => {
      this.brands = res.data
      this.$store.commit('saveDefaultBrand', res.data[0].id )
      this.brands.unshift({ shortname: null, name: 'All Brands' },)
    })
  }

  getStores() {
    if (this.$store.state.stores.data.length > 0 && this.$store.state.stores.expires && moment(this.$store.state.stores.expires).isAfter(moment())) {
      this.stores = this.$store.state.stores.data
    } else {
      //axios.get(`${process.env.VUE_APP_REDBOOK_ENDPOINT}/api/v1/stores/all`)
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/store/all`)
      .then(res => {
        let data = res.data.data;
        /*
        data = data.map((s:any) => {return {
          id: s.store_uid,
          name: s.name,
          brand_id: s.brand_id,
          business_id: s.business_unit_id
        }})
        */
        this.stores = data
        this.stores.unshift({
          id: -2,
          name: 'All Stores',
          brand_alias: null,
          business_id: null
        })
        console.log("stores", this.stores);
        this.$store.commit('saveStoreCache', {
          data: data,
          expires: moment().add(2,'h').format()
        })
      })
    }

    if (this.$route.query.business_id) {
      this.selected_store = this.stores.find((s:any) => s.business_id == this.$route.query.business_id)
    }
  }

  filterStore() {
    if (this.selected_brand == null) {
      this.stores = this.$store.state.stores.data
    } else {
      this.stores = this.$store.state.stores.data.filter((s:any) => s.brand_id == this.selected_brand)
    }
  }

  toggleFilterPanel(e:any) {
    this.show_filter = !this.show_filter
  }

  @Watch('$route', { immediate: true, deep: true })
  onQueryChange(data: any, to: any) {
    if (this.init) {
      if (data.query.brand_id) {
        this.selected_brand = data.query.brand_id
        this.params.brand_id = data.query.brand_id
      } else {
        this.selected_brand = null
        delete this.params.brand_id
      }

      if (Object.keys(data.query).length == 0) {
        console.log('empty!');
        this.getTransaction(this.params)
      } else {
        console.log('not empty!');
        this.getTransaction(data.query)
      }
    }
  }
}
