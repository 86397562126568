import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    default_brand: null,
    user: {
      email: null,
      expire_at: null,
      token: null
    },
    stores: {
      data: [],
      expires: ''
    }
  },
  mutations: {
    saveUser(state, payload) {
      state.user = payload
    },
    saveDefaultBrand(state, payload) {
      state.default_brand = payload
    },
    saveStoreCache(state, payload) {
      state.stores.data = payload.data
      state.stores.expires = payload.expires
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
