
import { Component, Vue, Watch } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import axios from 'axios'

@Component({
  components: { Navigation }
})

export default class App extends Vue {}
