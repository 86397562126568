
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios'

interface getTransactionParams {
  page: string
  per_page: string
  brand_id?: string | null
  business_id?: string
  sync_date_from?: string
  sync_date_to?: string
}

@Component
export default class Store extends Vue {
  is_busy:boolean = true
  init:boolean = false
  stores: any[] = []
  current_page = this.$route.query.page || "1"
  per_page = this.$route.query.per_page || "50"
  total_rows = null
  fields = ['name', 'business_id','store_number','brand','location']
  syncing: boolean = false

  params: any = { 
    page: this.current_page,
    per_page: this.per_page
  }

  breadcrumbs = [{
    text: 'PRC V.2',
    to: '/transactions'
  },{
    text: 'Stores',
    active: true
  }]

  mounted() {
    this.getStore(this.params)
  }

  onChangePage(page:string) {
    if (this.init) {
      this.params.page = page
      this.$router.push({
        name: 'Store',
        query: { ...this.params }
      })
    }
  }

  getStore(query:any) {
    axios.get(`${process.env.VUE_APP_API_ENDPOINT}/store`, {
      params: query
    }).then(res => {
      this.stores = res.data.data
      this.current_page = res.data.page_idx
      this.per_page = res.data.per_page
      this.total_rows = res.data.total
      this.init = true
      this.is_busy = false
    }).catch(err => {
      console.log(err);
    })
  }

  syncStore() {
    this.syncing = true
    axios.put(`${process.env.VUE_APP_API_ENDPOINT}/store/sync`)
    .then(res => {
      this.$bvModal.show('successModal')
      this.syncing = false
    }).catch(err => {
      this.$bvModal.show('errorModal')
      this.syncing = false
      console.log(err);
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  onQueryChange(data: any, to: any) {
    if (this.init) {
      this.getStore(data.query)
    }
  }
}
