import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Transaction from '@/views/Transaction.vue'
import Store from '@/views/Store.vue'
import Brand from '@/views/Brand.vue'
import User from '@/views/User.vue'
import Auth from '@/views/Auth.vue'
import BrandSetting from '@/components/BrandSetting.vue'


Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
    {
      path: '/',
      redirect: '/transactions'
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth
    },
    {
      path: '/transactions',
      name: 'Transaction',
      component: Transaction
    },
    {
      path: '/brands/:id',
      name: 'Brand',
      component: Brand,
      children:[{
        path: '',
        name: 'BrandSetting',
        component: BrandSetting,
        props: true
      }]
    },{
      path: '/stores',
      name: 'Store',
      component: Store,
    },{
      path: '/users',
      name: 'User',
      component: User,
    }
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
