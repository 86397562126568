
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios'

@Component
export default class Brand extends Vue {
  is_busy:boolean = true
  init:boolean = false
  brands: any[] = []
  fields = ['name', {key: 'shortname', label: 'Alias'}, 'group_name', {key: 'id', label: 'Settings' }]
  syncing: boolean = false

  breadcrumbs = [{
    text: 'PRC V.2',
    to: '/transactions'
  },{
    text: 'Brands',
    active: true
  }]

  created() {
    this.getBrand()
  }

  async getBrand() {
    try
    {
      let res = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/brand`);
      this.brands = res.data
      this.init = true
      this.is_busy = false
    }
    catch(err){
      console.log(err);
    }
  }

  syncBrand() {
    this.syncing = true
    axios.put(`${process.env.VUE_APP_API_ENDPOINT}/brand/sync`)
    .then(res => {
      this.$bvModal.show('successModal')
      this.syncing = false
    }).catch(err => {
      this.$bvModal.show('errorModal')
      this.syncing = false
      console.log(err);
    })
  }

  settingModal(brand_id:string) {
    console.log(brand_id);    
  }
}
